<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Import PBN file
			</div>
		</div>

		<div>
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<upload
					fileType="pbn"
					:rules="getUploadRules"
					:form.sync="form"
					:fileFormDataKey="fileFormDataKey"
				/>
				<checkbox-field
					name="Group my deals on import"
					v-model="autogroup"
				/>
				<checkbox-field
					name="Classify these deals while importing"
					v-model="prepopulate"
				/>
				<div
					data-element="prepopulate-options"
					v-if="prepopulate"
				>
					<range-field
						name="Experience level"
						:optionLabel="['value', 'text']"
						:options="experienceLevels"
						rules=""
						:lazy="true"
						:showRail="false"
						:labelMarks="experienceLevelMarks"
						v-model="config.experienceLevel"
					/>
					<select-field
						name="Bidding systems"
						:options="biddingSystemTypeOptions"
						:emptyAllowed="false"
						v-model="biddingSystemType"
					/>
					<select-field
						v-if="getShowSpecificBiddingSystems"
						name="Specific bidding systems (Blank for any)"
						:options="biddingSystemOptions"
						v-model="config.biddingSystems"
						multiple
					/>
					<focus-keywords
						:focusVal="config.focus"
						:keywordsVal="config.keywords"
						:formLoaded="formLoaded"
						entryRoute="teaching"
						@onKeyword="updateKeywords"
						@onFocus="updateFocus"
					/>
					<select-field
						name="Tags"
						:tags="true"
						:multiple="true"
						:options="tagOptions"
						v-model="config.tags"
					/>
				</div>
				<alert
					v-if="isEmptyBidsAlertVisible"
					:text="getEmptyBidsAlertText"
				>
					<template #action>
						<div data-element="empty-bids-alert-actions">
							<btn
								text="Yes"
								@click.native="approveImport"
								size="sm"
								colour="white"
							/>
							<btn
								text="Cancel"
								@click.native="cancelImport"
								size="sm"
								colour="white"
							/>
						</div>
					</template>
				</alert>
				<actions
					:actions="getActions"
					@actionClick="onActionClick"
					:isWaiting="isWaiting"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>
	import Upload                from '@/components/uploads/Upload';
	import Alert                 from '@/components/ui/Alert';
	import Btn                   from '@/components/ui/Btn';
	import CheckboxField         from '@/components/forms/CheckboxField';
	import RangeField            from '@/components/forms/RangeField';
	import SelectField           from '@/components/forms/SelectField';
	import FocusKeywords         from '@/components/forms/FocusKeywords';
	import Actions               from '@/components/ui/Actions';
	import actionClick           from '@/mixins/actionClick';
	import invalidFormMessage    from '@/mixins/invalidFormMessage';
	import api                   from '@/services/api';
	import { experienceLevels }  from '@/consts';
	import { biddingSystems }    from '@/consts';
	import { DealParser }        from '@bridge128/bridge128-game-logic-lib';

	export default {
		layout: 'v2default',

		components: {
			Upload,
			Alert,
			Btn,
			CheckboxField,
			RangeField,
			SelectField,
			FocusKeywords,
			Actions
		},

		mixins: [actionClick, invalidFormMessage],

		data: () => ({
			isWaiting: undefined,
			formLoaded: false,
			fileFormDataKey: 'file',
			form: undefined,
			autogroup:   false,
			prepopulate: false,
			tags: [],
			config: {
				experienceLevel: 1,
				biddingSystems:  [],
				keywords:        [],
				tags:            [],
				focus:           []
			},
			experienceLevels: experienceLevels,
			experienceLevelMarks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
			biddingSystemType: 'specific',
			biddingSystemTypeOptions: [
				{
					text: 'Specific bidding systems',
					value: 'specific'
				},
				{
					text: 'None',
					value: 'none'
				}
			],
			biddingSystemOptions: biddingSystems.map((biddingSystem) => {
				return {
					text: biddingSystem.value,
					value: biddingSystem.value
				};
			}),
			isEmptyBidsAlertVisible: false,
			dealsWithEmptyBids: []
		}),
		computed: {
			getCrumbRoute () {
				return '/teaching/deals';
			},
			getActions () {
				return {
					secondary: [
						{
							text: 'Import',
							actionId: 'submit',
							btnId: 'submit'
						},
						{
							text: 'Cancel',
							route: this.getCrumbRoute
						}
					].filter(Boolean)
				};
			},
			tagOptions () {
				if (!this.tags) {
					return false;
				}
				return this.tags.map((tag) => {
					return {
						text: tag,
						value: tag
					};
				});
			},
			getSizeInMegabytes () {
				const formData = this.form;
				let size = 0;
				for (const entry of formData.entries()) {
					const formComponent = entry[1];
					if (formComponent instanceof Blob) {
						size += formComponent.size;
					} else {
						size += formComponent.length;
					}
				}
				return (size / 1024 / 1024);
			},
			getCanDirectImport () {
				return this.getSizeInMegabytes < 1;
			},
			getImportMethod () {
				if (this.getCanDirectImport) {
					return 'importDealsFromPBN';
				}
				return 'queueImportDealsFromPBN';
			},
			getSuccessNotificationString () {
				if (this.getCanDirectImport) {
					return 'The PBN has been imported.';
				}
				return 'Large PBN files might not appear straight away. Check your deal library later.';
			},
			getShowSpecificBiddingSystems () {
				return Array.isArray(this.config.biddingSystems);
			},
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: '/teaching/deals',
						text: 'Deal library'
					},
					{
						path: '/teaching/deals/create',
						text: 'Import PBN file'
					}
				];
			},
			getUploadRules () {
				return {
					required: true,
					pbn: 'pbn'
				};
			},
			getEmptyBidsAlertText () {
				const dealsWithEmptyBidsString = this.dealsWithEmptyBids.join(', ');
				return `Deals with empty auction (board ${dealsWithEmptyBidsString}) will not be imported. Continue and import all others?`;
			}
		},
		watch: {
			'config.biddingSystems' (newVal) {
				if (Array.isArray(newVal)) {
					this.biddingSystemType = 'specific';
					return;
				}
				this.biddingSystemType = 'none';
			},
			biddingSystemType (newVal) {
				if (newVal === 'specific') {
					if (Array.isArray(this.config.biddingSystems)) {
						return false;
					}
					this.config.biddingSystems = [];
					return;
				}
				if (this.config.biddingSystems === null) {
					return false;
				}
				this.config.biddingSystems = null;
			}
		},
		mounted () {
			this.setTags();
		},
		methods: {
			async parseFile (file) {
				if (file && file instanceof Blob) {
					return file.text();
				}
			},
			parseDeal (text, name) {
				if (text) {
					return DealParser.fromPBNFileContent(text, {
						name
					});
				}
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}

				const file = this.form?.get(this.fileFormDataKey);
				const text = await this.parseFile(file);
				const parsedGames = this.parseDeal(text, file?.originalname);

				if (parsedGames?.length) {
					const gamesWithoutBids = parsedGames.filter(game => !game.bids.length);
					if (gamesWithoutBids.length > 0) {
						this.isEmptyBidsAlertVisible = true;
						this.dealsWithEmptyBids = gamesWithoutBids.map(game => game.board);
					} else {
						this.isEmptyBidsAlertVisible = false;
						this.dealsWithEmptyBids = [];
						await this.importFile();
					}
				}
			},
			async approveImport () {
				await this.importFile();
			},
			cancelImport () {
				this.isEmptyBidsAlertVisible = false;
				this.dealsWithEmptyBids = [];
			},
			async setTags () {
				const tags = await api.deals.getTags();
				if (!tags) {
					return;
				}
				this.tags = tags;
				this.formLoaded = true;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async importFile () {
				this.isWaiting = 'submit';
				const response = await api.deals[this.getImportMethod]({
					file: this.form,
					autogroup: this.autogroup,
					config: this.prepopulate ? this.config : {}
				});
				if (!response) {
					this.isWaiting = undefined;
					return false;
				}
				this.isWaiting = undefined;
				await this.$router.push(this.getCrumbRoute);
				this.$store.commit('ui/showNotification', {
					notification: this.getSuccessNotificationString
				});
			},
			updateKeywords (val) {
				this.config.keywords = val;
			},
			updateFocus (val) {
				this.config.focus = val;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-element='form'] {
		margin-bottom: 0;
		[data-element='empty-bids-alert-actions'] {
			display: flex;
			margin-left: auto;

			[data-component='btn'] {
				margin-left: rem(10);
			}
		}
	}

</style>
